import { FC } from 'react';
import IconProps from './IconProps';
import { dataAttributeProps } from '../../../utils/ComponentUtils';

const CogIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      onClick={onClick}
      {...dataAttributeProps(props)}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5108 5.17094C10.8898 3.60969 13.1102 3.60969 13.4892 5.17094C13.734 6.17949 14.8895 6.65811 15.7758 6.11809C17.1478 5.28212 18.7179 6.85218 17.882 8.22416C17.3419 9.11044 17.8205 10.266 18.8291 10.5108C20.3903 10.8898 20.3903 13.1102 18.8291 13.4892C17.8205 13.734 17.3419 14.8895 17.882 15.7758C18.7179 17.1478 17.1478 18.7179 15.7758 17.882C14.8895 17.3419 13.734 17.8205 13.4892 18.8291C13.1102 20.3903 10.8898 20.3903 10.5108 18.8291C10.266 17.8205 9.11045 17.3419 8.22417 17.882C6.85218 18.7179 5.28212 17.1478 6.11809 15.7758C6.65811 14.8895 6.17949 13.734 5.17094 13.4892C3.60969 13.1102 3.60969 10.8898 5.17094 10.5108C6.17949 10.266 6.6581 9.11045 6.11809 8.22416C5.28212 6.85218 6.85218 5.28212 8.22417 6.11809C9.11045 6.6581 10.266 6.17949 10.5108 5.17094Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6663 12C14.6663 13.4728 13.4725 14.6666 11.9997 14.6666C10.5269 14.6666 9.33301 13.4728 9.33301 12C9.33301 10.5272 10.5269 9.33331 11.9997 9.33331C13.4725 9.33331 14.6663 10.5272 14.6663 12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CogIcon;
